"use client";

import React, { useCallback, useContext } from "react";
import classNames from "classnames";
import { useAuctionFilterProps } from "@/types/auction";

type AuctionPaginationArgs = {
  page: number;
  size: number;
  total: number;
};

interface AuctionPagination {
  filterProps: useAuctionFilterProps;
  scrollRef?: React.RefObject<HTMLDivElement>;
  auctionText?: any;
}

const PAGE_BUFFER = 2; // Number of pages before/after current page
const MAX_DISPLAYED_PAGES = 5; // Max pages shown (including ellipsis)

const PaginationContext = React.createContext<any>(null);

const PaginationButton = ({ direction = "left" }) => {
  const { options, setOptions } = useContext(PaginationContext);
  const paginationArgs = options?.pagination as AuctionPaginationArgs;
  const maxPages = Math.ceil(paginationArgs.total / paginationArgs.size);

  const canGoLeft = direction === "left" && paginationArgs.page > 1;
  const canGoRight = direction === "right" && paginationArgs.page < maxPages;

  const setPage = useCallback(() => {
    if (canGoLeft) {
      setOptions((prev: any) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          page: paginationArgs.page - 1,
          hasPaginated: true,
        },
      }));
    } else if (canGoRight) {
      setOptions((prev: any) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          page: paginationArgs.page + 1,
          hasPaginated: true,
        },
      }));
    }
  }, [paginationArgs, canGoLeft, canGoRight]);

  const iconClass = classNames("w-4 h-4 group-hover:!text-white", {
    "rotate-180": direction === "right",
    "pointer-events-none right": direction === "right" && !canGoRight,
    "pointer-events-none left": direction === "left" && !canGoLeft,
  });

  const iconWrapperClass = classNames(
    "bg-white hover:bg-blue group hover:!text-white text-black-primary flex items-center p-[9px] rounded-md hover:opacity-80 transition-all duration-500 cursor-pointer m-2",
    {
      "bg-aliceblue-dark hidden":
        (!canGoLeft && direction === "left") ||
        (!canGoRight && direction === "right"),
    }
  );

  return (
    <div className={iconWrapperClass} onClick={setPage}>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className={iconClass}
      >
        <path
          d="M15.7051 7.70443L11.1251 12.2944L15.7051 16.8844L14.2951 18.2944L8.29512 12.2944L14.2951 6.29443L15.7051 7.70443Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

const AuctionPagination: React.FC<AuctionPagination> = ({
  filterProps,
  scrollRef = null,
  auctionText = null,
}) => {
  const { options, setOptions } = filterProps;
  const paginationArgs = options?.pagination as AuctionPaginationArgs;
  const maxPages = Math.ceil(paginationArgs.total / paginationArgs.size);
  const currentPage = paginationArgs.page;

  const changePage = useCallback(
    (index: number) => {
      if (index !== currentPage && index >= 1 && index <= maxPages) {
        setOptions((prev: any) => ({
          ...prev,
          pagination: { ...prev.pagination, page: index, hasPaginated: true },
        }));
      }
    },
    [paginationArgs, currentPage, maxPages]
  );

  // Function to generate page numbers with ellipsis logic
  const generatePageNumbers = () => {
    let items = [] as any;

    if (maxPages <= 1) return [];

    if (maxPages <= MAX_DISPLAYED_PAGES) {
      // If total pages are within the limit, show all pages
      for (let i = 1; i <= maxPages; i++) {
        items.push({ page: i, displayText: i.toString() });
      }
    } else {
      // Show first page
      items.push({ page: 1, displayText: "1" });

      // Add ellipsis if needed
      if (currentPage > PAGE_BUFFER + 2) {
        items.push("...");
      }

      // Show buffer pages around current page
      let start = Math.max(2, currentPage - PAGE_BUFFER);
      let end = Math.min(maxPages - 1, currentPage + PAGE_BUFFER);

      for (let i = start; i <= end; i++) {
        items.push({ page: i, displayText: i.toString() });
      }

      // Add ellipsis before last page
      if (currentPage < maxPages - PAGE_BUFFER - 1) {
        items.push("...");
      }

      // Show last page
      items.push({ page: maxPages, displayText: maxPages.toString() });
    }

    return items;
  };

  return (
    <PaginationContext.Provider value={{ options, setOptions, scrollRef }}>
      <div className="w-full flex items-center justify-center space-x-3">
        <PaginationButton direction="left" />

        {generatePageNumbers().map((item: any, index: number) => {
          if (item === "...") {
            return (
              <span key={index} className="px-2 text-lg">
                ...
              </span>
            );
          }

          return (
            <button
              key={index}
              className={classNames(
                "px-3 py-2 rounded-md transition duration-300",
                { "bg-blue text-white": currentPage === item.page },
                { "hover:bg-gray-200": currentPage !== item.page }
              )}
              onClick={() => changePage(item.page)}
            >
              {item.displayText}
            </button>
          );
        })}

        <PaginationButton direction="right" />
      </div>
    </PaginationContext.Provider>
  );
};

export default AuctionPagination;

"use client"

import { AUCTION_ITEMS_PER_PAGE } from "@/lib/auctions";
import { auctionFilterProps, useAuctionFilterProps } from "@/types/auction";
import { useState } from "react";

type CustomSettings = {
    pagination?: any;
    sorting?: any;
    filtering?: any;
    searchItemNo?: any;
    auctionPartId?: number;
}

export const useAuctionFilter = (customSettings?: CustomSettings): useAuctionFilterProps => {
    const [options, setOptions] = useState<auctionFilterProps>({
        pagination: {
            page: 1,
            size: AUCTION_ITEMS_PER_PAGE,
            total: 0,
            hasPaginated: false,
            ...customSettings?.pagination,
        },
        sorting: {
            options: [],
            selections: [],
            ...customSettings?.sorting,
        },
        filtering: {
            selections: [],
            ...customSettings?.filtering,
        },
        isFiltersReady: false,
        searchItemNo: ""
    });

    const buildSortingParams = () => {
        const sorting = options.sorting?.selections;

        if (!sorting) {
            return {};
        }

        return {
            [sorting?.[0]?.sortBy]: sorting?.[0]?.sortOrder,
        };
    }

    const buildFilterParams = () => {
        const filters = options.filtering?.selections;

        if (!filters) {
            return {};
        }

        const filter = filters.reduce(
            (acc: any, selection: any) => {
                const multipleEntries = filters.filter((k: any) => k.queryKey === selection.queryKey)

                return {
                    ...acc,
                    [selection.queryKey]: multipleEntries?.length > 1
                        ? [...multipleEntries.map((k: any) => k.value)]
                        : selection.value,
                };
            },
            {}
        );

        return filter;
    }

    return { options, setOptions, buildFilterParams, buildSortingParams };
};

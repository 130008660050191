import React from "react";

interface AuctionBarFilterItemProps {
  item: any;
  removeFilter: React.Dispatch<any>;
}

const AuctionBarFilterItem: React.FC<AuctionBarFilterItemProps> = ({
  item,
  removeFilter,
}) => {
  const selectedLabel = item?.selectedLabel || item?.value;

  return (
    <div className="flex flex-row items-center justify-between group px-3 py-2 neutral-dark-gray border bg-aliceblue border-aliceblue-dark text-toast-blue rounded-md focus:outline-none focus:border-blue-600 text-sm leading-none">
      <span className="whitespace-nowrap select-none font-semibold mr-2 leading-none">
        {selectedLabel}
      </span>
      <svg
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => removeFilter(item.id)}
        className="cursor-pointer md:h-4 md:w-5"
      >
        <path
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill="#2D2D32"
        />
      </svg>
    </div>
  );
};

export default AuctionBarFilterItem;

import React from "react";
import BlueHeart from "@/icons/blue-heart.svg";

interface NotFoundProps {
  title: string;
  description: string;
  buttonElement?: any;
}

const NotFoundText: React.FC<NotFoundProps> = ({
  title,
  description,
  buttonElement,
}) => {
  return (
    <div className="flex justify-center w-full rounded-lg bg-gradient-to-t from-neutral-200 to-neutral-300 text-paragraph py-8 px-5">
      <div className="flex flex-col items-center justify-center space-y-6 lg:space-y-4 text-center md:text-left">
        <img src={BlueHeart.src} alt="Blue heart" className="w-6 h-6" />
        <h3 className="text-24 lg:text-32 font-semibold">{title}</h3>
        <p className="font-normal text-16">{description}</p>
        {buttonElement ? buttonElement : buttonElement}
      </div>
    </div>
  );
};

export default NotFoundText;

"use client";

import React, { use, useCallback, useEffect } from "react";
import AuctionBarFilterItem from "./FilterItem";
import { Auction, AuctionItem, useAuctionFilterProps } from "@/types/auction";
import FilterListBox from "./FilterListBox";
import classNames from "classnames";
import Input from "@/components/Elements/Input";

interface AuctionBarActionsPanelProps {
  filterProps: useAuctionFilterProps;
  showFilter?: boolean;
  searchValue?: string;
  setSearchValue?: any;
}

const AuctionBarActionsPanel: React.FC<AuctionBarActionsPanelProps> = ({
  filterProps,
  showFilter = true,
  searchValue = "",
  setSearchValue = false,
}) => {
  const [mobileFilterOpen, setMobileFilterOpen] = React.useState(false);
  const { options, setOptions } = filterProps;

  const filtering = options?.filtering!;
  const filterOptions = filtering?.options as any;

  const clearFilter = () => {
    setOptions((prev: any) => ({
      ...prev,
      filtering: {
        ...prev.filtering,
        selections: [],
      },
    }));
  };

  const filterSelections = showFilter ? filtering?.selections : [];

  const removeFilter = (id: any) => {
    setOptions((prev: any) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: 1,
      },
      filtering: {
        ...prev.filtering,
        selections: prev.filtering.selections.filter(
          (item: any) => item.id !== id
        ),
      },
    }));
  };

  const SvgFilter = () => (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.5A3.502 3.502 0 0 0 18.355 8H21a1 1 0 1 0 0-2h-2.645a3.502 3.502 0 0 0-6.71 0H3a1 1 0 0 0 0 2h8.645A3.502 3.502 0 0 0 15 10.5zM3 16a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H21a1 1 0 1 0 0-2h-9.145a3.502 3.502 0 0 0-6.71 0H3z"
        fill="currentColor"
      />
    </svg>
  );

  const activeFilters = filtering?.selections?.length;

  return (
    <div
      className={classNames(
        "w-full flex-col justify-start items-start inline-flex"
      )}
    >
      {showFilter && filterOptions && (
        <div className="flex flex-row justify-between items-center w-full mt-2 mb-4 md:mb-4 md:mb-0 gap-4 md:gap-0 flex-wrap">
          <div className="flex flex-row space-x-2 md:hidden text-base text-neutral-dark-gray font-semibold whitespace-nowrap text-left leading-none items-center h-full">
            <SvgFilter />
            <span className="hidden md:block">
              Filtrera {activeFilters ? `(${activeFilters})` : ""}
            </span>
            <span
              className="block md:hidden"
              onClick={() => {
                setMobileFilterOpen((prev) => !prev);
              }}
            >
              {!mobileFilterOpen ? "Visa filter" : "Dölj Filter"}{" "}
              {activeFilters ? `(${activeFilters})` : ""}
            </span>
          </div>
          <div className="block md:hidden">
            {setSearchValue && (
              <span className="ml-6">
                <Input
                  type="number"
                  placeholder="Sök Rop-nr"
                  value={searchValue}
                  icon="search"
                  buttonSize="md"
                  className="md:!text-base !text-base"
                  inputClassName="md:!text-base !text-base max-w-[150px] !leading-[1]"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </span>
            )}
          </div>
          <div
            className={classNames(
              "w-full justify-start items-start inline-flex md:mt-4 bg-white p-2 md:py-4 md:px-6 rounded-lg mt-6 md:mt-0 z-[985]",
              {
                "hidden md:flex": !mobileFilterOpen,
              }
            )}
          >
            <div className="justify-start items-start flex scrollbar-hide md:overflow-visible w-full rounded-lg rounded-lg md:mt-0">
              <div className="flex-row md:flex-col space-x-4 md:space-x-0 justify-start md:items-start inline-flex items-center">
                <div className="justify-start items-center inline-flex flex-wrap gap-2 md:gap-4 md:flex-nowrap">
                  <div className="hidden mr-6 md:flex flex-row space-x-2 text-base text-gray-gark font-semibold whitespace-nowrap text-left leading-normal items-center h-full">
                    <SvgFilter />
                    <span className="font-light">Filtrera</span>
                  </div>
                  {filterOptions?.map((filter: any, index: number) => {
                    const options = filter?.options;
                    const allHits =
                      options &&
                      options.reduce(
                        (acc: any, curr: any) => curr?.hits || 0 + acc,
                        0
                      );

                    // Always show itemEndTime
                    const isItemEndTime = options?.matchKey == "itemEndTime";

                    return (
                      <div
                        className={classNames(
                          "bg-white rounded-lg justify-start items-end md:gap-2 flex",
                          {
                            "hidden md:flex":
                              !isItemEndTime &&
                              (options.length === 0 || allHits === 0),
                          }
                        )}
                        key={index}
                      >
                        <FilterListBox
                          showDropDownSelection
                          dontShowSelected
                          setOptions={setOptions}
                          options={options}
                          placeholder={filter.label}
                          selections={filtering.selections}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={classNames(
          "self-stretch items-center flex flex-col md:flex-row -mt-4 mb-4 lg:inline-flex gap-5",
          {
            "hidden md:flex": !mobileFilterOpen,
          }
        )}
      >
        <div className="self-start inline-flex mt-6 md:mt-4">
          <div className="justify-center items-center gap-2.5 flex">
            {filterSelections?.map((item: any, index: number) => (
              <AuctionBarFilterItem
                key={index}
                item={item}
                removeFilter={removeFilter}
              />
            ))}
          </div>

          {filterSelections.length ? (
            <div className="pl-2 pr-5 py-2 rounded justify-center items-center gap-2.5 flex ml-4">
              <span
                role="button"
                className="text-red text-xs font-semibold leading-tight whitespace-nowrap"
                onClick={() => clearFilter()}
              >
                Rensa filter
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AuctionBarActionsPanel;

"use client";

import { AuctionItem } from "@/types/auction";
import FavoriteButton from "./FavoriteButton";
import AuctionBidHistory from "../../AuctionBidHistory";
import AuctionBidField from "../../AuctionBidField";
import BidCountDown from "./BidCountDown";
import CurrentBid from "./CurrentBid";
import Button from "@/components/Elements/Button";
import { getAuctionItemLink } from "@/lib/links";
import Link from "next/link";
import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import NoImageFound from "@/components/NoImageFound";
import {
  checkIfAuctionItemEnded,
  BID_TIME_REMAINING_COUNTDOWN,
} from "@/lib/auctions";
import useUserStore from "@/stores/useUserStore";
import Image from "next/image";
import AuctionEndedImage from "../../AuctionEndedImage";
import { useShallow } from "zustand/react/shallow";
import useAuctionItemFavorite from "@/hooks/useAuctionItemFavorite";
import { useSearchParams } from "next/navigation";
import ArrowButton from "@/components/Elements/ArrowButton";
import { formatPrice, parseDate, parseToDate } from "@/lib/utils";
import useIsVisible from "@/hooks/useVisible";
import PopUpSvg from "@/icons/popup.svg";
import "swiper/css";
import "swiper/css/pagination";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import useModalStore from "@/hooks/useModalStore";
import { MarkerSvg } from "@/lib/temp";
import ModalElement from "@/components/Modal/Modal";

interface AuctionCallCardProps {
  item: AuctionItem;
  prefetchedBidsData?: any;
  fetchBids?: boolean;
  isFetching?: boolean;
  user?: any;
  totalItems?: number | null;
  serverTime: any;
  isLoading?: boolean;
}

const FavoriteButtonMemomized = React.memo(
  ({ auctionItemFavorite }: { auctionItemFavorite: any }) => (
    <FavoriteButton auctionItemFavorite={auctionItemFavorite} />
  )
);

const AuctionBidFieldMemomized = React.memo(
  ({
    hasEnded,
    leadingAutoBid,
    auctionItem,
    leadingBid,
    isLeadingAutoBid,
    ref,
  }: {
    hasEnded: boolean;
    auctionItem: AuctionItem;
    leadingBid: number;
    leadingAutoBid: any;
    isLeadingAutoBid: boolean;
    ref: any;
  }) => (
    <AuctionBidField
      hasEnded={hasEnded}
      leadingAutoBid={leadingAutoBid}
      auctionItem={auctionItem}
      leadingBid={leadingBid}
      ref={ref}
      isLeadingAutoBid={isLeadingAutoBid}
    />
  )
);

AuctionBidFieldMemomized.displayName = "AuctionBidFieldMemomized";
FavoriteButtonMemomized.displayName = "FavoriteButtonMemomized";

const AuctionItemCard: React.FC<AuctionCallCardProps> = ({
  item,
  prefetchedBidsData,
  serverTime,
  isLoading,
  isFetching = false,
}) => {
  const itemId = Number(item.itemId);
  const searchParams = useSearchParams();
  const linkRef = searchParams.get("sida") || "1";
  const cardRef = useRef<HTMLDivElement>(null);
  const bidDivRef = useRef<HTMLDivElement>(null);
  const inputBidFieldRef = useRef<HTMLDivElement>(null);
  const { loginModal } = useModalStore();
  const [bidModal, setBidModal] = useState({
    isOpen: false,
    content: "",
  });
  const [toggleMobileDiv, setToggleMobileDiv] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const { user, userInfo } = useUserStore(useShallow((state) => state));
  const [showAllBids, setSHowAllBids] = useState(false);
  const itemLink = item && getAuctionItemLink(item, linkRef);
  const hasEnded = checkIfAuctionItemEnded(item?.itemEndTime, serverTime);
  const [imageIndexLightbox, setImageIndexLightbox] = useState(0);

  const cardId = `auction-item-card-${itemId}`;

  const itemNameFormatted = `${
    item.itemSortNo ? `Rop ${item.itemSortNo}: ` : " "
  } ${item.itemTitle}`;

  const auctionItemFavorite = useAuctionItemFavorite({
    itemId: itemId,
    userId: user?.id,
  });

  const calculateTimeRemaining = useCallback(() => {
    const endTimeStr = parseDate(item.itemEndTime);
    const serverDateObject = parseToDate(serverTime);
    const now = serverDateObject.getTime();

    return endTimeStr - now;
  }, [item.itemEndTime, serverTime]);

  // If the time remaining is less than 2 minutes, we consider the auction to be ending
  const isEnding = calculateTimeRemaining() <= BID_TIME_REMAINING_COUNTDOWN;

  const formatDate = (date: string) => {
    const d = parseToDate(date);

    if (isNaN(d.getTime())) {
      return "Datum ej specificerat";
    }

    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(d.getDate()).padStart(2, "0")}`;
  };

  const bidData = prefetchedBidsData;

  const leadingAutoBid = bidData?.leadingAutoBid;

  const isLeadingAutoBid =
    leadingAutoBid && leadingAutoBid?.autoUserId === user?.id;

  const isWinner =
    user && bidData ? item?.itemWinningUserId === user?.id : false;

  const leadingBid = bidData?.bids
    ? bidData?.bids[0]?.historyBidValue
    : item.itemMinBid;

  const isItemActive = parseDate(item.itemEndTime) >= parseDate(serverTime);

  const containerClass = classNames(
    "bg-light-grey rounded-xl overflow-hidden flex flex-col items-start justify-start box-border transition-shadow duration-300 ease-in-out"
  );

  const images = item?.images ? Object.values(item.images) : [];

  const largeImages = useMemo(() => {
    return item?.images
      ? Object.values(item.images).map((image: any) => ({
          src: `${image}?width=1900`,
        }))
      : [];
  }, [item?.images]);

  const boxContainerClass = classNames(
    "w-full md:w-4/12 xl:w-6/12 self-stretch bg-white flex flex-col items-start justify-start p-4 py-5 md:py-8 md:pl-4 md:px-8 box-border md:h-full",
    {
      "!pb-0 md:!pb-8": toggleMobileDiv,
    }
  );

  const timeContainerClass = classNames(
    "block bottom-0 -mt-1 sm:mt-0 relative sm:absolute w-full py-2 text-white text-xs bg-toast-blue text-white md:rounded-b-lg z-10",
    {
      "!bg-danger !text-white": isEnding && !hasEnded,
    }
  );

  const imageContainerClass = classNames(
    "relative w-full select-none md:h-full bg-aliceblue",
    {
      "opacity-80": !isItemActive,
    }
  );

  const formatCollectShowObject = (data: any, linkAttr?: string) => {
    if (data?.useLink) {
      const link = `${itemLink}#faq-${linkAttr}`;

      return (
        <Link
          href={link}
          className="inline-flex flex-row space-x-1 items-center"
        >
          <span>{data?.text}</span>{" "}
          <img src={PopUpSvg?.src} width={16} alt="popup" />
        </Link>
      );
    }

    return data?.text || "Kontakta oss för mer information";
  };

  const openBidModal = () => {
    if (!bidModal || !loginModal) return;

    if (!user?.id) {
      loginModal.setTitle("Logga in på Tovek.se");
      loginModal.onOpen();
      return;
    }

    setBidModal((prev: any) => ({ ...prev, isOpen: true }));
  };

  const bidModalContent = () => (
    <div className="full-width md:hidden mt-6">
      <div className="container">
        <div className="flex flex-col self-stretch relative font-semibold text-base mb-4 px-6">
          <span className="font-normal">
            {hasEnded
              ? "Slutpris"
              : bidData && bidData?.bids?.length > 0
              ? "Ledande bud"
              : "Utgångspris"}
            :{" "}
          </span>
          <span className="text-2xl">
            <CurrentBid
              auctionItem={item}
              bids={bidData?.bids}
              showLeadingUser
              small
            />
          </span>
        </div>
        <div
          className={classNames(
            " p-6 -mb-8 md:-mb-10 flex flex-col items-start justify-start gap-[24px] bg-aliceblue text-paragraph"
          )}
          ref={bidDivRef}
        >
          <BidCountDown
            item={item}
            hasEnded={hasEnded}
            isWinner={isWinner}
            bidData={bidData}
            serverTime={serverTime}
            isFetching={isFetching}
          />
          <AuctionBidFieldMemomized
            leadingAutoBid={leadingAutoBid}
            hasEnded={hasEnded}
            auctionItem={item}
            leadingBid={leadingBid}
            isLeadingAutoBid={isLeadingAutoBid}
            ref={inputBidFieldRef}
          />
          <div className="bg-aliceblue py-4 w-full">
            <AuctionBidHistory
              auctionItem={item}
              user={user}
              hasEnded={hasEnded}
              bidData={bidData}
              bidIsLoading={false}
              showAllBids={showAllBids}
              setSHowAllBids={setSHowAllBids}
              noMaxHeight
              extraClass="max-h-[300px]"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const imagesStringified = JSON.stringify(largeImages);

  const memoizedLightbox = useMemo(() => {
    return (
      <Lightbox
        styles={{
          container: { backgroundColor: "rgba(255, 255, 255, .95)" },
          root: {
            "--yarl__color_button": "#2D2D32",
            "--yarl__color_button_disabled": "#dfdfdf",
            "--yarl__color_button_active": "#0a5599",
            "--yarl__thumbnails_thumbnail_border_color": "transparent",
            "--yarl__thumbnails_thumbnail_active_border_color": "#397ab6",
            "--yarl__button_filter": "none",
            "--yarl__thumbnails_container_background_color": "#0a5599",
            "--yarl__thumbnails_thumbnail_background": "transparent",
            "--yarl__color_backdrop": "none",
            "--yarl__slide_icon_loading_color": "#397ab6",
          },
        }}
        plugins={[Zoom, Thumbnails]}
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        slides={largeImages}
      />
    );
  }, [openLightbox, imagesStringified]);

  return (
    <div id={cardId} className={containerClass} ref={cardRef}>
      {memoizedLightbox}
      <ModalElement
        isOpen={bidModal?.isOpen}
        modalTitleClass="!mb-0"
        size="lg"
        modalTitle={itemNameFormatted}
        modalBody={bidModalContent()}
        onClose={() => {
          setBidModal((prev: any) => ({ ...prev, isOpen: false }));

          if (cardRef.current) {
            cardRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      />

      <div className="flex flex-col md:flex-row justify-start bg-white w-full">
        <div className="w-full md:w-6/12 xl:w-5/12 p-2 md:p-4">
          <div className={imageContainerClass}>
            <FavoriteButtonMemomized
              auctionItemFavorite={auctionItemFavorite}
            />

            <div className="absolute top-0 left-0 uppercase text-black bg-white text-xs font-bold p-3 rounded-br-lg z-[100] -m-[2px]">
              {formatDate(item.itemEndTime)}
            </div>

            {images?.length && !hasEnded ? (
              <div
                onClick={() => setOpenLightbox(true)}
                className="cursor-zoom-in h-full w-full bg-aliceblue"
              >
                <Image
                  src={`${images[0]}?width=800`}
                  alt={item.itemTitle || "Auktionsföremål"}
                  className="w-full h-full rounded-lg object-contain max-h-[500px]"
                  height={800}
                  width={1200}
                />
                <div className="absolute rounded-lg bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
              </div>
            ) : hasEnded ? (
              <div
                onClick={() => setOpenLightbox(true)}
                className="cursor-zoom-in h-full w-full"
              >
                {" "}
                <AuctionEndedImage
                  image={images?.[0] || ""}
                  text={hasEnded ? "" : "Ingen bild hittades"}
                />{" "}
              </div>
            ) : (
              <NoImageFound
                containerClass="rounded-lg"
                text={hasEnded ? "" : "Ingen bild hittades"}
              />
            )}

            <div className={timeContainerClass}>
              <div className="container">
                <BidCountDown
                  isFetching={isFetching}
                  item={item}
                  hasEnded={hasEnded}
                  isWinner={isWinner}
                  bidData={bidData}
                  serverTime={serverTime}
                  noCountDownTextColor
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className={boxContainerClass}>
          <div className="self-stretch flex flex-col items-start justify-start break-words">
            <span className="text-2xl md:text-24 xl:text-32 self-stretch relative leading-1 font-semibold text-blue">
              <Link href={itemLink || "#"} className="!no-underline">
                {item.itemSortNo ? `Rop ${item.itemSortNo}: ` : ""}
                <h3 className="text-2xl md:text-24 xl:text-32 self-stretch relative inline leading-1 font-semibold text-blue">
                  {item.itemTitle}
                </h3>
              </Link>
            </span>

            {item?.address?.addressTitle && (
              <div className="self-stretch relative leading-1 font-normal text-paragraph text-lg md:text-1xl mt-2 flex flex-row space-x-2 items-center">
                <span className="text-muted-bg">
                  <MarkerSvg />
                </span>
                <span>{item?.address?.addressTitle}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col xl:space-y-0 md:h-full xl:space-x-10 md:justify-between xl:flex-row w-full items-start">
            <div className="flex flex-col space-y-8 w-full flex-grow md:h-full">
              <div className="self-stretch relative text-sm text-black md:h-full">
                <div className="flex-1 flex flex-col items-start justify-start h-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[4px] md:space-y-6 md:h-full">
                    <div className="flex flex-col self-stretch relative leading-1 font-semibold text-base mt-6 mb-2 md:mt-12 md:mb-8">
                      <span className="font-normal">
                        {hasEnded
                          ? "Slutpris"
                          : bidData && bidData?.bids?.length > 0
                          ? "Ledande bud"
                          : "Utgångspris"}
                        :{" "}
                      </span>
                      <span className="text-xxl">
                        <CurrentBid
                          auctionItem={item}
                          bids={bidData?.bids}
                          showLeadingUser
                        />
                      </span>
                    </div>

                    <div className="flex flex-col sm:flex-row gap-3 md:hidden mb-6 md:mb-0">
                      {!hasEnded && (
                        <Button
                          onClick={openBidModal}
                          className="h-full flex-grow md:text-base px-4 md:px-2 !w-fit"
                        >
                          Lägg max-bud
                        </Button>
                      )}
                      <Link
                        href={itemLink || "#"}
                        className="!no-underline flex-grow"
                        shallow
                      >
                        <Button
                          bgColor="white"
                          textColor="blue"
                          variant="outlined"
                          borderColor="blue"
                          className="hover:bg-blue hover:text-white !hover:bg-opacity-1 md:text-base px-4 md:px-2"
                        >
                          Se mer info
                        </Button>
                      </Link>
                    </div>
                    <div className="relative leading-1 text-profile-gray-dark bg-gray-ultra-light text-slate-gray-dark p-4 text-14 flex space-y-2 flex-col rounded-xl w-full md:w-fit h-fit !mt-auto my-6 mb-0 md:my-0">
                      <div className="self-stretch relative leading-1">
                        <span className="font-semibold">Utgångspris:</span>{" "}
                        {formatPrice(item.itemMinBid)}
                      </div>
                      <div className="self-stretch relative leading-1">
                        <span className="font-semibold">Moms:</span>{" "}
                        {item.itemVatValue}%
                      </div>
                      <div className="self-stretch relative leading-1">
                        <span className="font-semibold">Slagavgift:</span>{" "}
                        {formatPrice(item.itemFeeValue)} exkl. moms
                      </div>
                      <div className="self-stretch relative leading-1 hidden md:block">
                        <span className="font-semibold">Visning:</span>{" "}
                        {formatCollectShowObject(
                          item.itemShowingInfo,
                          "viewing"
                        )}
                      </div>
                      <div className="self-stretch relative leading-1 hidden md:block">
                        <span className="font-semibold">Avhämtning:</span>{" "}
                        {formatCollectShowObject(
                          item.itemCollectInfo,
                          "pickup"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block w-full md:w-4/12 xl:w-4/12 rounded-tl-none rounded-tr-lg rounded-br-lg rounded-bl-none bg-aliceblue md:flex flex-col items-start justify-start p-6 box-border gap-[24px]">
          <div className="mb-6 md:mb-0">
            <BidCountDown
              isFetching={isFetching}
              item={item}
              hasEnded={hasEnded}
              isWinner={isWinner}
              bidData={bidData}
              serverTime={serverTime}
            />
          </div>

          <AuctionBidFieldMemomized
            leadingAutoBid={leadingAutoBid}
            hasEnded={hasEnded}
            auctionItem={item}
            leadingBid={leadingBid}
            isLeadingAutoBid={isLeadingAutoBid}
            ref={inputBidFieldRef}
          />
          <div className="mt-6 md:mt-0 w-full">
            <AuctionBidHistory
              auctionItem={item}
              user={user}
              hasEnded={hasEnded}
              bidData={bidData}
              showAllBids={showAllBids}
              setSHowAllBids={setSHowAllBids}
              bidIsLoading={false}
              noMaxHeight
              extraClass="max-h-[300px]"
              showMorePosition="title"
            />
          </div>

          <Link
            href={itemLink || "#"}
            className="flex w-full !no-underline text-center justify-center mt-auto"
            shallow
          >
            <ArrowButton text="Se mer info om rop" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuctionItemCard;

"use client";

import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { MultiSelect } from "react-multi-select-component";
import "react-loading-skeleton/dist/skeleton.css";

type IconType = "chevron-down" | "sort-arrow";

interface FilterListBoxInterface {
  placeholder?: string;
  dontShowSelected?: boolean;
  options: any;
  setOptions?: any;
  buttonClass?: string;
  showDropDownSelection?: boolean;
  icon?: IconType;
  selections?: any;
  sortingLayout?: boolean;
  selectClass?: string;
}

const ButtonIcon: React.FC<{ iconType: IconType; isOpen: boolean }> = ({
  iconType = "chevron-down",
  isOpen = false,
}) => {
  let icon;

  switch (iconType) {
    case "chevron-down":
      icon = (
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${isOpen ? "transform rotate-180" : ""}`}
        >
          <path
            d="M10.59 0.590027L6 5.17003L1.41 0.590027L0 2.00003L6 8.00003L12 2.00003L10.59 0.590027Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "sort-arrow":
      icon = (
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-[10px] h-[10px] md:w-[15px] md:h-[17px]"
        >
          <path
            d="M7.0833 0L0 7.0833H14.1667L7.0833 0ZM0 9.9167L7.0833 17L14.1667 9.9167H0Z"
            fill="#2D2D32"
          />
        </svg>
      );
      break;
  }

  return icon;
};

const FilterListBox: React.FC<FilterListBoxInterface> = ({
  placeholder,
  options,
  setOptions,
  selections,
  sortingLayout = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const optionsDiv = useRef<HTMLDivElement>(null);

  const allHits =
    options &&
    options.reduce((acc: any, curr: any) => curr?.hits || 0 + acc, 0);

  // Handle outside clicks
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (optionsDiv.current && !optionsDiv.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsDiv]);

  const formattedOptions = options.map((option: any) => ({
    value: option.value,
    label: option.label,
    id: option.id,
    matchKey: option.matchKey,
    selectedLabel: option.selectedLabel,
    queryKey: option.queryKey,
  }));

  const selectedValues = formattedOptions.filter((opt: any) =>
    selections.some((sel: any) => sel.id === opt.id)
  );

  const handleMultiSelectChange = (newSelectedOptions: any[]) => {
    // Filter out invalid selections
    const validSelections = newSelectedOptions.filter(
      (opt) => opt && opt.id && opt.label
    );

    // Get the current dropdown's queryKey to isolate its selections
    const currentQueryKey = options[0]?.queryKey;

    // Preserve selections from other FilterListBox instances
    const preservedSelections = selections.filter(
      (existing: any) => existing.queryKey !== currentQueryKey
    );

    // Add valid selections from the current dropdown
    const updatedCurrentSelections = validSelections.map((opt) => ({
      id: opt.id,
      matchKey: opt.matchKey,
      selectedLabel: opt.label,
      queryKey: opt.queryKey,
      value: opt.value,
    }));

    // Merge preserved selections with updated selections for the current dropdown
    const finalSelections = [
      ...preservedSelections,
      ...updatedCurrentSelections,
    ];

    if (typeof setOptions === "function") {
      setOptions((prev: any) => ({
        ...prev,
        filtering: {
          ...prev.filtering,
          pagination: {
            ...prev.pagination,
            page: 1,
          },
          selections: finalSelections,
        },
      }));
    }
  };

  const customValueRenderer = (
    selected: { label: string }[],
    _options: any
  ) => {
    return selected.length
      ? placeholder + ` (${selected.length})`
      : placeholder;
  };

  return (
    <div
      className={classNames(
        "flex flex-col md:flex-row justify-center relative md:flex md:items-center select-none",
        {
          "opacity-50 pointer-events-none":
            options.length === 0 || (!sortingLayout && allHits === 0),
        }
      )}
      ref={optionsDiv}
    >
      {/* Mobile Select Box */}
      <MultiSelect
        options={formattedOptions}
        valueRenderer={customValueRenderer}
        hasSelectAll={false}
        ClearSelectedIcon={null}
        value={selectedValues}
        onChange={handleMultiSelectChange}
        labelledBy={placeholder || ""}
        overrideStrings={{
          selectSomeItems: selectedValues.length
            ? `(${selectedValues.length}) valda`
            : placeholder ?? "",
          allItemsAreSelected: "Alla valda",
          search: "Sök...",
          clearSearch: "Rensa sökning",
          clearSelected: "Rensa valda",
          noOptions: "Inga alternativ",
          selectAll: "Välj alla",
          selectAllFiltered: "Välj alla (filtrerade)",
          create: "Skapa",
        }}
      />

      {/* Desktop Dropdown */}
      {/* <div className="hidden md:block">
        <button
          className={classNames(
            "flex flex-row justify-between group items-center w-auto sm:w-48 px-3 py-2 neutral-dark-gray bg-white border-2 border-white rounded-md focus:outline-none focus:border-blue-600 text-14 md:text-16 leading-none",
            {
              [buttonClass as string]: buttonClass,
            }
          )}
          onClick={toggleSelect}
        >
          <span
            className={classNames("select-none font-normal mr-3 leading-none", {
              "font-semibold": foundSelections?.length,
            })}
          >
            {labelText}{" "}
            {foundSelections?.length ? `(${foundSelections.length})` : ""}
          </span>
          <ButtonIcon iconType={icon} isOpen={isOpen} />
        </button>
        {isOpen && (
          <div className="absolute left-0 w-48 py-2 px-2 z-[999] mt-2 bg-white rounded-lg shadow-xl top-[100%] h-auto max-h-[400px] overflow-y-scroll scrollbar-hide">
            {options.map((option: any, index: number) => {
              const hits = option?.hits || 0;
              if (hits === 0 && !sortingLayout) return null;

              const optionIsSelected = selections.some(
                (selection: any) => selection.id === option.id
              );

              const selectClass = classNames(
                "h-[16px] w-[16px] rounded-full",
                { "bg-black": optionIsSelected },
                {
                  "bg-white border border-black": !optionIsSelected,
                }
              );

              return (
                <a
                  className="block px-2 cursor-pointer p-2 text-gray-800 !no-underline hover:bg-gray-light/10 transition-all duration-200"
                  onClick={() => updateFilter(index)}
                  key={index}
                >
                  {showDropDownSelection ? (
                    <div className="flex items-center flex-start space-x-2 -m-2 py-2">
                      <span className={selectClass}></span>
                      <span className="flex-1">
                        {option.label}{" "}
                        {// {!sortingLayout && (<span className="text-muted-gray">({hits})</span>)} }
                      </span>
                    </div>
                  ) : (
                    <span>
                      {option.label}{" "}
                      {// {!sortingLayout && (<span className="text-muted-gray">({hits})</span>)}}
                    </span>
                  )}
                </a>
              );
            })}
          </div>
        )}
      </div> */}
    </div>
  );
};

export default FilterListBox;

import Image from "next/image";
import React from "react";
import NoImageFound from "../NoImageFound";

interface AuctionEndedImageProps {
  image: string;
  text?: any;
  mainText?: string;
}

const AuctionEndedImage: React.FC<AuctionEndedImageProps> = ({
  image,
  text = "Ingen bild hittades",
  mainText,
}) => {
  return (
    <div className="relative w-full h-full duration-500 transition-all group select-none">
      <div className="absolute text-2xl top-0 left-0 h-full w-full flex justify-center text-center items-center uppercase text-white font-bold p-3 z-[100]">
        <span className="group-hover:hidden">
          {mainText ? mainText : "Avslutad"}
        </span>
      </div>
      <div className="bg-black/50 rounded-lg z-10 duration-500 transition-all absolute top-0 left-0 w-full h-full group-hover:bg-transparent transition-all duration-500"></div>
      {image === "" ? (
        <NoImageFound
          text={text}
          containerClass="relative h-full w-full rounded-lg object-cover overflow-hidden shrink-0 hover:scale-115 transition-all duration-1000 cursor-zoom-in select-none"
        />
      ) : (
        <Image
          className="relative w-full rounded-lg object-contain overflow-hidden shrink-0 hover:scale-115 transition-all duration-1000 cursor-zoom-in select-none"
          src={`${image}?width=800`}
          alt="Slider Image"
          height="500"
          width="400"
        />
      )}
    </div>
  );
};

export default AuctionEndedImage;

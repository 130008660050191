"use client";

import React, { useCallback, useContext, useRef } from "react";
import classNames from "classnames";
import { useAuctionFilterProps } from "@/types/auction";
import useDebounce from "@/hooks/useDebounce";

type AuctionListItemPaginationArgs = {
  page: number;
  size: number;
  total: number;
  startPage: number;
  lastClosedSortNo?: number;
  hasPaginated?: boolean;
  startItemSortNo?: number;
  allAvailablePages?: number[];
};

interface AuctionListItemPagination {
  filterProps: useAuctionFilterProps;
  isLoading?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
  auctionText?: any;
}

const MAX_DISPLAYED_PAGES = 8;

const PaginationContext = React.createContext<any>(null);

// Returns an array of page objects for every page
const getAllPageRanges = (paginationArgs: AuctionListItemPaginationArgs) => {
  const { total, size, startItemSortNo } = paginationArgs as any;
  // When startItemSortNo isn't 1, pad total up to the next full page and add one extra item.
  // Otherwise, use the provided total.
  const effectiveTotal = total;

  // The last item number in our padded scheme:
  const lastItemNumber = startItemSortNo + effectiveTotal - 1;
  const totalPages = Math.ceil(effectiveTotal / size);

  let ranges = [];
  for (let i = 1; i <= totalPages; i++) {
    const startIndex = startItemSortNo + (i - 1) * size;
    // For the last page, force the end to be the padded last item.
    const endIndex = i === totalPages ? lastItemNumber : startIndex + size - 1;
    ranges.push({ page: i, displayText: `${startIndex}-${endIndex}` });
  }
  return ranges;
};

// Returns a condensed version for desktop (up to MAX_DISPLAYED_PAGES, then ellipsis, then last page)
const getDesktopPageNumbers = (
  paginationArgs: AuctionListItemPaginationArgs
) => {
  return getAllPageRanges(paginationArgs); // Return full list of pages
};

const PaginationButton = ({ direction = "left" }) => {
  const { options, setOptions } = useContext(PaginationContext);
  const paginationArgs = options?.pagination as AuctionListItemPaginationArgs;

  // Apply the commented-out logic here.
  const { total, size, startItemSortNo } = paginationArgs;
  const effectiveTotal =
    startItemSortNo !== 1 ? Math.ceil(total / size) * size + 1 : total;
  const totalPages = Math.ceil(effectiveTotal / size);

  // Determine the new page based on direction.
  const newPage =
    direction === "left"
      ? paginationArgs.page - 1
      : direction === "right"
      ? paginationArgs.page + 1
      : paginationArgs.page;

  // Determine whether navigation is allowed.
  const canGo =
    newPage >= 1 && newPage <= totalPages && newPage !== paginationArgs.page;
  const canGoLeft = direction === "left" && newPage >= 1;
  const canGoRight = direction === "right" && newPage <= totalPages;

  const setPage = useCallback(() => {
    if (canGoLeft) {
      setOptions((prev: any) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          page: paginationArgs.page - 1,
          hasPaginated: true,
        },
        searchItemNo: "",
      }));
    } else if (canGoRight) {
      setOptions((prev: any) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          page: paginationArgs.page + 1,
          hasPaginated: true,
        },
        searchItemNo: "",
      }));
    }
  }, [canGoLeft, canGoRight, paginationArgs.page, setOptions]);

  const iconClass = classNames("w-4 h-4 group-hover:!text-black", {
    "rotate-180": direction === "right",
    "pointer-events-none right": direction === "right" && !canGoRight,
    "pointer-events-none left": direction === "left" && !canGoLeft,
  });

  const iconWrapperclass = classNames(
    "hover:bg-white border border-gray-300 group text-black-primary flex items-center p-3 rounded-md hover:opacity-80 transition-all cursor-pointer mx-0",
    {
      "bg-aliceblue-dark hidden":
        (!canGoLeft && direction === "left") ||
        (!canGoRight && direction === "right"),
    }
  );

  return (
    <div className={iconWrapperclass} onClick={setPage}>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className={iconClass}
      >
        <path
          d="M15.7051 7.70443L11.1251 12.2944L15.7051 16.8844L14.2951 18.2944L8.29512 12.2944L14.2951 6.29443L15.7051 7.70443Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

const AuctionListItemPagination: React.FC<AuctionListItemPagination> = ({
  filterProps,
  scrollRef = null,
  auctionText = null,
}) => {
  const { options, setOptions } = filterProps;
  const paginationArgs = options?.pagination as AuctionListItemPaginationArgs;
  // Desktop uses the condensed version
  const desktopPages = getDesktopPageNumbers(paginationArgs);
  // Mobile gets the full list
  const mobilePages = getAllPageRanges(paginationArgs);
  const currentPage = paginationArgs.page || 1;

  const updatePath = (index: number) => {
    if (index !== currentPage) {
      changePage(index);
    }
  };

  const changePage = useCallback(
    (index: number) => {
      const { total, size, startItemSortNo } = paginationArgs;
      const effectiveTotal =
        startItemSortNo !== 1 ? Math.ceil(total / size) * size + 1 : total;
      const totalPages = Math.ceil(effectiveTotal / size);

      if (
        !paginationArgs ||
        index === currentPage ||
        index < 1 ||
        index > totalPages
      )
        return;

      setOptions((prev: any) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          page: index,
          hasPaginated: true,
        },
        searchItemNo: "",
      }));
    },
    [paginationArgs, currentPage, setOptions]
  );

  const allPages = desktopPages.map((pageObj: any, index: number) => {
    if (pageObj === "...") {
      return (
        <span key={index} className="p-3">
          ...
        </span>
      );
    }
    const allAvailablePages = paginationArgs?.allAvailablePages;
    const isDisabled =
      allAvailablePages && !allAvailablePages.includes(pageObj.page);

    const pageClass = classNames(
      "p-3 py-2 text-center text-base bg-white hover:bg-blue hover:text-white rounded-md transition cursor-pointer select-none border border-solid border-gray-300",
      {
        "font-bold !bg-blue text-white":
          currentPage === pageObj.page && !isDisabled,
      },
      {
        "!bg-transparent !text-gray-600":
          currentPage !== pageObj.page && isDisabled,
      },
      {
        "!bg-gray-600 !text-white": currentPage === pageObj.page && isDisabled,
      }
    );
    return (
      <span
        key={index}
        className={pageClass}
        onClick={() => updatePath(pageObj.page)}
      >
        {pageObj.displayText}
      </span>
    );
  });

  return (
    <PaginationContext.Provider value={{ options, setOptions, scrollRef }}>
      <div className="w-full md:w-fit rounded-lg text-paragraph px-0 md:px-0">
        {/* Desktop Pagination */}
        <div
          className="hidden md:flex w-full flex-row justify-between rounded-lg items-center gap-4"
          ref={scrollRef}
        >
          {auctionText && (
            <span className="font-semibold items-center whitespace-nowrap">
              {auctionText}
            </span>
          )}
          <PaginationButton />
          <div className="flex flex-row flex-wrap items-center space-x-2 space-y-1">
            {allPages}
          </div>
          <PaginationButton direction="right" />
        </div>

        {/* Mobile Pagination Dropdown */}
        <div className="md:hidden">
          <select
            className="block w-full p-2 border rounded"
            value={currentPage}
            onChange={(e) => changePage(Number(e.target.value))}
          >
            {mobilePages.map((option) => (
              <option key={option.page} value={option.page}>
                {auctionText}: {option.displayText}
              </option>
            ))}
          </select>
        </div>
      </div>
    </PaginationContext.Provider>
  );
};

export default AuctionListItemPagination;

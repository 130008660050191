"use client";

import AuctionItemCard from "@/components/Auctions/AuctionItem/Card/Index";
import Skeleton from "react-loading-skeleton";
import { AuctionListItemProps } from "@/types/auction";
import {
  createContext,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import AuctionBarActionsPanel from "../AuctionBarFilter/ActionPanel";
import NotFoundText from "@/components/NotFoundText";
import AuctionListItemPagination from "../AuctionListItemPagination";
import Button from "@/components/Elements/Button";
import Input from "@/components/Elements/Input";
import useServerTime from "@/hooks/useServerTime";
import AnimateHeight from "react-animate-height";
import "react-loading-skeleton/dist/skeleton.css";
import AuctionPagination from "../AuctionPagination";

export const AuctionItemListContext = createContext<any>(null);

const AuctionListItems: React.FC<AuctionListItemProps> = forwardRef(
  (
    {
      auctionItems,
      fetchBids = false,
      visibleItemsData = false,
      auctionItemBids = [],
      hidePagination = false,
      hidePaginationTop = false,
      filterProps,
      isFetching = false,
      showFilter = true,
      isLoading = false,
      error = false,
      maxItems = 0,
      readMoreLink = false,
      searchLayout = false,
      showResetFilterOnNotFound = false,
      setItemNoSearchValue: setSearchValue = false,
      itemNoSearchValue: searchValue = "",
    },
    ref
  ) => {
    const { options, setOptions } = filterProps;
    const paginationArgs = options?.pagination as any;
    const totalItems = auctionItems?.length || 0;

    const serverTime = useServerTime();

    const [animateDuration, setAnimateDuration] = useState(0);

    const totalPaginationItems = !!paginationArgs.total;

    useEffect(() => {
      const timer = setTimeout(() => {
        setAnimateDuration(1000);
      }, 5000);
      return () => clearTimeout(timer);
    }, []);

    const clearFilter = () => {
      setOptions((prev: any) => ({
        ...prev,
        filtering: {
          ...prev.filtering,
          selections: [],
        },
        pagination: {
          ...prev.pagination,
          page: 1,
        },
        searchItemNo: "",
      }));
    };

    const visibleItemsDataList = auctionItems?.filter((i: any) => !i.isHidden);
    const firstVisibleId = visibleItemsDataList
      ? visibleItemsDataList[0]?.itemId
      : null;

    return (
      <AuctionItemListContext.Provider
        value={{
          auctionItems,
          auctionItemBids,
          options,
          isLoading,
          setOptions,
          fetchBids,
        }}
      >
        {!hidePagination && !error && (
          <AuctionBarActionsPanel
            showFilter={showFilter}
            filterProps={filterProps}
            searchValue={searchValue}
          />
        )}

        {paginationArgs && totalPaginationItems && !hidePaginationTop && (
          <div
            className={classNames(
              "flex flex-row items-center mx-auto justify-center mb-5 md:my-5"
            )}
          >
            {!searchLayout ? (
              <AuctionListItemPagination
                filterProps={filterProps}
                auctionText="Visa rop"
              />
            ) : (
              <AuctionPagination
                filterProps={filterProps}
                auctionText="Visa rop"
              />
            )}

            {setSearchValue && (
              <span className="ml-6 hidden md:block">
                <Input
                  type="number"
                  placeholder="Sök Rop-nr"
                  value={searchValue}
                  icon="search"
                  buttonSize="md"
                  className="md:!text-base !text-base border border-gray-200"
                  inputClassName="md:!text-base !text-base max-w-[150px] !leading-[1] focus:!shadow-md"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </span>
            )}
          </div>
        )}
        {(isLoading || !serverTime) && (
          <div className="self-stretch overflow-x-scroll md:overflow-hidden flex flex-col items-stretch gap-[10px] md:gap-[32px] text-sm xs-full-width pl-6 md:pl-0 my-8">
            {Array.from({ length: 3 }).map((_, i) => (
              <div className="flex flex-row" key={i}>
                <Skeleton height={352} containerClassName="w-[30%]" />
                <Skeleton height={352} containerClassName="flex-1" />
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col w-full">
          <div
            className={classNames(
              "flex flex-col space-y-8 [&>:not(.is-hidden):first-child]:!mt-0",
              {
                "opacity-80": isFetching,
              }
            )}
          >
            {error && !auctionItems && serverTime && (
              <NotFoundText
                title="Hittade inga rop"
                description="Något gick fel när vi försökte att hämta ropen. Försök igen senare."
              />
            )}

            {!isLoading &&
              auctionItems &&
              typeof visibleItemsData === "number" &&
              visibleItemsData < 1 && (
                <NotFoundText
                  title="Hittade inga rop"
                  description="Inga rop finns att visa för valda inställningar, pröva att ändra dina filter."
                  buttonElement={
                    showResetFilterOnNotFound && (
                      <Button
                        bgColor="transparent"
                        textColor="blue"
                        borderColor="blue"
                        onClick={clearFilter}
                      >
                        Återställ filter
                      </Button>
                    )
                  }
                />
              )}

            {!!auctionItems &&
              !isLoading &&
              serverTime &&
              auctionItems.map((item: any, index: any) => {
                if (maxItems && index >= maxItems) return null;

                const bidsData = auctionItemBids
                  ? auctionItemBids?.find(
                      (itemData: any) =>
                        String(itemData?.itemId) === String(item.itemId)
                    )
                  : [];

                const isHidden = item?.isHidden;

                return (
                  <AnimateHeight
                    key={item.itemId}
                    duration={animateDuration}
                    height={isHidden ? 0 : "auto"}
                    animateOpacity
                    className={classNames({
                      "!m-0 is-hidden": isHidden,
                      "!m-0": item.itemId === firstVisibleId,
                    })}
                  >
                    <AuctionItemCard
                      item={item}
                      prefetchedBidsData={bidsData}
                      fetchBids={fetchBids}
                      totalItems={totalItems}
                      serverTime={serverTime}
                      isLoading={isLoading}
                      isFetching={isFetching}
                    />
                  </AnimateHeight>
                );
              })}
          </div>
        </div>
        {!isLoading && readMoreLink && maxItems < totalItems ? (
          <div className="flex justify-center w-full">{readMoreLink}</div>
        ) : null}
        {paginationArgs && totalPaginationItems ? (
          <div className="flex flex-row items-center mx-auto my-4 justify-center my-5">
            {!searchLayout ? (
              <AuctionListItemPagination
                filterProps={filterProps}
                auctionText="Visa rop"
              />
            ) : (
              <AuctionPagination
                filterProps={filterProps}
                auctionText="Visa rop"
              />
            )}
            {setSearchValue && (
              <span className="ml-6 hidden md:block">
                <Input
                  type="number"
                  placeholder="Sök Rop-nr"
                  value={searchValue}
                  icon="search"
                  buttonSize="md"
                  className="md:!text-base !text-base border border-gray-200"
                  inputClassName="md:!text-base !text-base max-w-[150px] !leading-[1] focus:!shadow-md"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </span>
            )}
          </div>
        ) : (
          <div className="h-4" />
        )}
      </AuctionItemListContext.Provider>
    );
  }
);

AuctionListItems.displayName = "AuctionListItems";

export default AuctionListItems;

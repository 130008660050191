import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";
import Image from "next/image";
import classNames from "classnames";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import AuctionVideo from "../../AuctionVideo";
import Link from "next/link";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

interface AuctionCardSliderProps {
  images: any;
  swiperSettings?: any;
  videos?: any;
  linkTo?: string | false;
  noZoom?: boolean;
  imageClassName?: string;
}

const Icon: React.FC<{ direction: string }> = ({ direction }) => {
  const iconClass = classNames({
    "rotate-0": direction === "prev",
    "rotate-180": direction === "next",
  });

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={iconClass}
    >
      <rect
        width="40"
        height="40"
        rx="8"
        transform="matrix(-1 0 0 1 40 0.5)"
        fill="white"
      />
      <mask
        id="mask0_947_7488"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="25"
      >
        <rect
          width="24"
          height="24"
          transform="matrix(-1 0 0 1 32 8.5)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_947_7488)">
        <path
          d="M22.6 26.5L24 25.1L19.4 20.5L24 15.9L22.6 14.5L16.6 20.5L22.6 26.5Z"
          fill="#2D2D32"
        />
      </g>
    </svg>
  );
};

const AuctionCardSlider: React.FC<AuctionCardSliderProps> = ({
  images,
  videos,
  linkTo = false,
  noZoom = false,
  imageClassName = "",
  swiperSettings,
}) => {
  const sliderRef = useRef<any>(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [imageIndexLightbox, setImageIndexLightbox] = useState(0);

  const [dynamicSwiperSettings, setDynamicSwiperSettings] = useState<any>({
    autoplay: true,
    delay: 3000,
  });

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;

    sliderRef.current.swiper.slidePrev();

    setImageIndex(sliderRef?.current?.swiper?.activeIndex);
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;

    sliderRef.current.swiper.slideNext();

    setImageIndex(sliderRef?.current?.swiper?.activeIndex);
  }, []);

  const totalImages = images?.length || 0;

  const defaultSwiperSettings = {
    slidesPerView: 1,
    speed: 1500,
    loop: true,
    effect: "fade",
    navigation: false,
    pagination: {
      dynamicBullets: false,
      enabled: false,
    },
    ref: sliderRef,
    className: "h-full cursor-pointer",
    modules: [Pagination, Autoplay, EffectFade],
    onSlideChange: () => {
      setImageIndex(sliderRef?.current?.swiper?.activeIndex);
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
    },
    fadeEffect: { crossFade: true },
    // autoplay: dynamicSwiperSettings.autoplay
    //   ? {
    //       delay: dynamicSwiperSettings.delay,
    //       disableOnInteraction: false,
    //       pauseOnMouseEnter: false,
    //     }
    //   : undefined,
  };

  const imageClassNameProps = classNames(
    "relative h-full w-full rounded-t-lg rounded-b-none object-cover overflow-hidden hover:scale-115 transition-all duration-1000 cursor-pointer select-none",
    imageClassName
  );

  const largeImages =
    images?.map((image: any) => ({
      src: `${image}?width=1900`,
    })) || [];

  const largeVideos =
    videos?.map((video: any) => ({
      type: "video",
      src: video,
    })) || [];

  const slides = useMemo(
    () => [...largeVideos, ...largeImages],
    [largeVideos, largeImages]
  );

  const imagesStringified = JSON.stringify(slides);

  const memoizedLightbox = useMemo(
    () => (
      <Lightbox
        styles={{
          container: { backgroundColor: "rgba(255, 255, 255, .95)" },
          root: {
            "--yarl__color_button": "#2D2D32",
            "--yarl__color_button_disabled": "#dfdfdf",
            "--yarl__color_button_active": "#0a5599",
            "--yarl__thumbnails_thumbnail_border_color": "transparent",
            "--yarl__thumbnails_thumbnail_active_border_color": "#397ab6",
            "--yarl__button_filter": "none",
            "--yarl__thumbnails_container_background_color": "#0a5599",
            "--yarl__thumbnails_thumbnail_background": "transparent",
            "--yarl__color_backdrop": "none",
            "--yarl__slide_icon_loading_color": "#397ab6",
          },
        }}
        index={imageIndexLightbox}
        plugins={[Zoom, Thumbnails]}
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        slides={slides}
      />
    ),
    [openLightbox, imagesStringified]
  );

  return (
    <div
      className="relative h-full group"
      // onMouseEnter={() =>
      //   setDynamicSwiperSettings({ autoplay: true, delay: 2000 })
      // }
      // onMouseLeave={() => setDynamicSwiperSettings({ autoplay: false })}
    >
      {memoizedLightbox}
      <Link href={linkTo || ""}>
        <Swiper {...defaultSwiperSettings} {...swiperSettings}>
          {images &&
            images.map((image: any, index: any) => (
              <SwiperSlide
                onClick={() => {
                  !noZoom && setOpenLightbox(true);
                }}
                key={index}
              >
                <Image
                  className={imageClassNameProps}
                  src={`${image}?width=1000`}
                  alt="Slider Image"
                  height="500"
                  width="400"
                />
                <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100" />
              </SwiperSlide>
            ))}
          {/* {videos &&
          videos.map((video: any, index: any) => (
            <Link href={linkTo} key={index}>
              <SwiperSlide>
                  <AuctionVideo src={video} />
              </SwiperSlide>
            </Link>
          ))} */}
        </Swiper>
      </Link>
      {totalImages > 0 && imageIndex !== 0 && (
        <div
          className="hidden md:group-hover:block prev-arrow absolute z-10 -bottom-[30px] md:bottom-auto md:top-[50%] left-[51px] md:left-0 -translate-y-1/2 cursor-pointer [&>svg:hover]:-translate-x-[5px] duration-300 transition ml-3"
          onClick={handlePrev}
        >
          <Icon direction="prev" />
        </div>
      )}
      {totalImages > 0 && imageIndex < totalImages - 1 && (
        <div
          className="hidden md:group-hover:block next-arrow absolute z-10 -bottom-[30px] md:bottom-auto md:top-[50%] right-[51px] md:right-0 -translate-y-1/2 cursor-pointer [&>svg:hover]:translate-x-[5px] duration-300 transition mr-3"
          onClick={handleNext}
        >
          <Icon direction="next" />
        </div>
      )}
    </div>
  );
};

export default AuctionCardSlider;
